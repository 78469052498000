
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
import AdminService from '@/services/admin-service';
@Component({
  components: { DefaultLayout }
})
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
    fileUpload: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  @Watch('file')
  async onFileChange(val: any) {
    this.hasError = false;

    if (val && val.name) {
      let formData = new FormData();
      formData.append('files[]', val, val.name);
      try {
        this.isImporting = true;
        const response = await AdminService.processOrderByFile(formData);
        this.isImporting = false;
        this.filename = response.data.fileName;
        this.resultText = 'processed order sucessfully, sending email';
        this.shouldShowResults = true;
      } catch {
        this.resultText = 'something went wrong uploading the order';
        this.shouldShowResults = true;
        this.hasError = true;
        this.isImporting = false;
        return;
      }
      var reader = new FileReader();
      reader.onload = e => {};
      reader.readAsDataURL(val);
    }
  }
  /* Data */
  file: any = null;
  hasError: boolean = false;
  isImporting: boolean = false;
  shouldShowResults: boolean = false;
  filename: string = '';
  fileDescription: string = '';
  resultText: string = '';
  /* Utility Functions */
  onFileUploadClick() {
    this.file = null;
    let inputId = this.$refs.fileUpload.$refs.input.id;
    let el = document.getElementById(`${inputId}`);
    if (el) el.click();
  }
  /* Loaders */
  /* Mounted */
  /* Created */
}
